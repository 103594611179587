import httpsModule from "../util/http";
import {APP_BASE_URL} from "../util/constants";

const NOTIFICATION_ENDPOINT = `${APP_BASE_URL}notification/`;

const notificationController = {
    postNotification: async (notification) => {
        const response = await httpsModule.post(`${NOTIFICATION_ENDPOINT}`, notification);
        return response.data;
    },

    readNotifications: async (ids) => {
        const response = await httpsModule.put(`${NOTIFICATION_ENDPOINT}`, {ids});
        return response.data;
    },

    getUserNotifications: async () => {
        const response = await httpsModule.get(`${NOTIFICATION_ENDPOINT}`);
        return response.data;
    },
};

export default notificationController;