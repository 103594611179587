import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './tailwind.css'
import '@mantine/core/styles.css'; //import Mantine V7 styles needed by MRT
import '@mantine/dates/styles.css'; //if using mantine date picker features
import 'mantine-react-table/styles.css'; //import MRT styles
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';
import {MantineProvider} from "@mantine/core";
import reportWebVitals from './reportWebVitals';
import App from './App';
import {Notifications} from "@mantine/notifications";
import {ModalsProvider} from "@mantine/modals";
import ConfirmModal from "./components/modal/ConfirmModal";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <MantineProvider defaultColorScheme={"dark"}>
            <ModalsProvider modals={{confirm: ConfirmModal}}
                            modalProps={{
                                title: "Confirmar",
                                size: "xs",
                                centered: true,
                                withCloseButton: false,
                                zIndex: 999999
                            }}
            >
                <Notifications position="top-right" zIndex={999999} withCloseButton={true} autoClose={5000}/>
                <App/>
            </ModalsProvider>
        </MantineProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
