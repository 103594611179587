import {createContext, useCallback, useMemo, useState} from "react";
import LoadingSpinner from "../components/base/spinner/LoadingSpinner";

export const LoadingContext = createContext();

export const LoadingProvider = ({children}) => {
    const [isLoading, setIsLoading] = useState(false);

    const setLoading = useCallback((value) => {
        setIsLoading(value);
    }, []);

    const provider = useMemo(() => ({
        isLoading,
        setLoading
    }), [isLoading, setLoading]);

    return (
        <>
            {isLoading && <LoadingSpinner/>}
            <LoadingContext.Provider value={provider}>
                {children}
            </LoadingContext.Provider>
        </>
    );
}