import {createContext, useContext, useEffect, useRef, useState} from "react";
import notificationController from "../controller/notificationController";
import {getAuth} from "../util/functions";
import {IconAlertCircle, IconAlertTriangle, IconCircleCheck} from "@tabler/icons-react";
import SockJS from "sockjs-client";
import {Stomp} from "@stomp/stompjs";
import {useIsFirstRender} from "@mantine/hooks";
import {useNavigate} from "react-router-dom";
import {PERMISSION, ROUTES, WS_BASE_URL} from "../util/constants";

const NotificationsContext = createContext();

function NotificationsProvider({children}) {
    const navigate = useNavigate();
    const isFirstRender = useIsFirstRender();
    const [notifications, setNotifications] = useState([]);
    const [permission, setPermission] = useState(options.find((option) => option.key === Notification.permission));
    let showIndicator = notifications?.length > 0;
    let jwt = getAuth()[5];
    let isLoggedIn = getAuth()[0];

    const handleRequestPermission = () => {
        Notification.requestPermission().then((permission) => {
            setPermission(options.find((option) => option.key === permission));
        });
    };

    const fetchNotifications = async () => {
        const response = await notificationController.getUserNotifications();
        setNotifications(response.sort((a, b) => new Date(b.date) - new Date(a.date)));
    }

    const performNotificationsInitialFetch = () => {
        if (jwt) fetchNotifications()
    }

    const handleRedirect = (message) => {
        getAuth(PERMISSION.PRICING)[0]
            ? navigate(`${ROUTES.PRICING_QUOTATIONS.path}/${message}`)
            : navigate(`${ROUTES.MY_QUOTATIONS.path}/${message}`);
    }

    const handleRead = (id) => {
        notificationController
            .readNotifications([id])
            .then(() => {
                fetchNotifications();
            });
    }

    const handleReadAll = () => {
        notificationController
            .readNotifications(notifications.map((notification) => notification.id))
            .then(() => {
                fetchNotifications();
            });
        setNotifications([]);
    }

    const buildNotificationWithAction = (title, options, onClick) => {
        if (permission.key === 'granted') {
            const notification = new Notification(title, options);

            notification.onclick = (event) => {
                onClick();
            };
        }
    }

    const handleSendTestNotification = () => {
        buildNotificationWithAction(
            'Notificación de prueba',
            {
                body: 'Esto es una notificación de prueba enviada por el cotizador.',
                icon: '/favicon.ico',
            },
            () => {
                navigate(`${ROUTES.MY_QUOTATIONS.path}`);
            }
        );
    }

    const handlePushDesktopNotification = (notification) => {
        buildNotificationWithAction(
            notification.title,
            {
                body: notification.message,
                icon: '/favicon.ico',
            },
            () => {
                handleRead(notification.id);
                handleRedirect(notification.key);
            }
        );
    }

    useEffect(() => {
        if (jwt) {
            let stompClient = null;
            const socket = new SockJS(WS_BASE_URL);

            stompClient = Stomp.over(socket);
            /* discard the stompJs debug messages */
            stompClient.debug = (str) => {
            };

            stompClient.connect({Authorization: `Bearer ${jwt}`},
                () => {
                    stompClient.subscribe("/user/queue/notifications", (message) => {
                        const notification = JSON.parse(message.body);
                        handlePushDesktopNotification(notification);
                        fetchNotifications();
                    });
                },
                (error) => {
                    console.log('WebSocket connection error: ' + error);
                }
            );

            return () => {
                if (stompClient) stompClient.disconnect();
            };
        }
    }, [jwt]);

    useEffect(() => {
        performNotificationsInitialFetch();
    }, [jwt])

    useEffect(() => {
        setPermission(options.find((option) => option.key === Notification.permission));
    }, [Notification.permission]);

    /**
     * given that the NotificationsProvider is not unmounted in the App.js when we log off, is necessary to perform a clean up in the stored values
     * to avoid keeping the attributes from the old user in case we change accounts
     */
    useEffect(() => {
        if (!isLoggedIn) setNotifications([]);
    }, [isLoggedIn]);

    const contextValue = {
        notifications,
        setNotifications,
        permission,
        setPermission,
        showIndicator,
        performNotificationsInitialFetch,
        fetchNotifications,
        handleRedirect,
        handleRead,
        handleReadAll,
        handleRequestPermission,
        handleSendTestNotification,
    };

    return (
        <NotificationsContext.Provider value={contextValue}>
            {children}
        </NotificationsContext.Provider>
    );
}

function useNotifications() {
    return useContext(NotificationsContext);
}

const options = [
    {
        key: "granted",
        label: "Permitido",
        action: "Recibir notificación de prueba",
        icon: <IconCircleCheck size={18} style={{marginTop: 4, marginBottom: 4}}/>,
        color: "#26cc2140"
    },
    {
        key: "denied",
        label: "Denegado",
        action: "Solicitar",
        icon: <IconAlertTriangle size={18} style={{marginTop: 4, marginBottom: 4}}/>,
        color: "#cc212140"
    },
    {
        key: "default",
        label: "No solicitado",
        action: "Solicitar",
        icon: <IconAlertCircle size={18} style={{marginTop: 4, marginBottom: 4}}/>,
        color: "#dedb2540"
    }
]

export {NotificationsProvider, useNotifications};