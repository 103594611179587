import {NOTES_TYPES} from "../util/constants";

export const QUOTATION_INITIAL_STATE = {
    referencia: '',
    commercial: '',
    transportMode: '',
    modo: '',
    containerQty: '',
    containerData: [],
    origenCodigo: '',
    origenName: '',
    destinoCodigo: '',
    destinoName: '',
    pickupData: [],
    incoterm: '',
    valorMercaderia: '',
    valorSeguro: '',
    monedaMercaderia: 'USD',
    monedaSeguro: 'USD',
    commodityCodigo: '',
    commodityDescr: '',
    pesoTotal: '',
    cbmTotal: '',
    chargeableTotal: '',
    ctnTotal: '',
    pltTotal: '',
    stackable: 'YES',
    sellectedSell: null,
    sell: [],
    measureSystem: 'metric',
    shipmentDate: null,
    cost: [],
    info: [],
    status: '',
    packingData: [],
    agentData: [],
    contacts: [],
    docs: [],
    mailSubject: '',
    notes: [],
    client: {id: 1, razonSocial: '', reference: ''},
    disclaimers: [],
}

export const NEW_QUOTATION_INITIAL_STATE = {
    commercial: '',
    assignedTo: '',
    transportMode: '',
    modo: '',
    containerQty: '',
    containerData: [],
    origenCodigo: '',
    origenName: '',
    destinoCodigo: '',
    destinoName: '',
    pickupData: [],
    incoterm: '',
    valorMercaderia: '',
    valorSeguro: '',
    monedaMercaderia: 'USD',
    monedaSeguro: 'USD',
    commodityCodigo: '',
    commodityDescr: '',
    pesoTotal: '',
    cbmTotal: '',
    chargeableTotal: '',
    ctnTotal: '',
    pltTotal: '',
    stackable: 'YES',
    shipmentDate: null,
    notes: [],
    packingData: [],
    docs: [],
    client: {id: 0, nombreFantasia: '', reference: ''},
    measureSystem: 'metric',
};

export const NEW_EXPRESS_QUOTATION_INITIAL_STATE = {
    cliente: '',
    clientReference: '',
    ibExpress: '',
    obExpress: '',
    isDoc: 0,
    shipperAddress: {
        description: '',
        city: '',
        stateOrProvinceCode: '',
        postalCode: '',
        countryCode: '',
    },
    recipientAddress: {
        description: '',
        city: '',
        stateOrProvinceCode: '',
        postalCode: '',
        countryCode: '',
    },
    valorMercaderia: '',
    monedaMercaderia: 'USD',
    insur: 'FEDEX',
    pesoTotal: '',
    cbmTotal: '',
    chargeableTotal: '',
    ctnTotal: '',
    pltTotal: '',
    measureSystem: 'metric',
    packingData: [{ctnPlt: 'CTN', cantidad: '', peso: '', largo: '', ancho: '', alto: '', chargeable: ''}],
};

export const PICKUP_INITIAL_STATE = {
    direccion: '',
    codPostal: '',
    ciudad: '',
    estado: '',
    proveedor: '',
    nroOrden: '',
    volumen: '',
    peso: '',
};

export const COMMODITY_INITIAL_STATE = {
    commodityCodigo: '',
    commodityDescr: '',
}

export const UNLOCO_INITIAL_STATE = {
    code: '',
    name: '',
}

export const USER_INITIAL_STATE = {
    id: 0,
    code: '',
    name: '',
    mail: '',
    password: '',
    active: 0,
    isCommercial: 0,
    isThirdParty: 0,
    isManager: 0,
    permissions: [],
    roles: [],
    relatedCommercials: [],
    relatedUsers: [],
}

export const ORGANIZATION_INITIAL_STATE = {
    id: 0,
    nombreFantasia: '',
    razonSocial: '',
    rut: '',
    mail: '',
    direccion: '',
    codPostal: '',
    ciudad: '',
    paisCod: '',
    paisNombre: '',
    salesRep: '',
    salesRepName: '',
    userCodigo: '',
    userNombre: '',
    esCliente: false,
    esProveedor: false,
    cwCode: '',
    ibExpress: 'IP0',
    obExpress: 'IP0',
};

export const CHARGE_CODE_INITIAL_STATE = {
    id: 0,
    concept: '',
    description: '',
    tax: '',
    air: 0,
    sea: 0,
    road: 0,
    express: 0,
    insurance: 0,
}

export const PROVIDER_CONTACT_INITIAL_STATE = {
    id: 0,
    name: '',
    mail: '',
    primary: 0,
    services: [],
    traffics: [],
    comments: '',
}

export const PROVIDER_INITIAL_STATE = {
    id: 0,
    code: '',
    name: '',
    language: '',
    contacts: [],
    originCountries: [],
    destinationCountries: [],
    categories: [],
    services: [],
}

export const NEW_COST_CHARGE_INITIAL_STATE = {
    idCharge: 0,
    chargeIndex: 0,
    concept: '',
    description: '',
    currency: 'USD',
    costPerKg: 0,
    amount: 0,
    tax: '',
}

export const NEW_SELL_CHARGE_INITIAL_STATE = {
    idCharge: 0,
    chargeIndex: 0,
    concept: '',
    description: '',
    costCurrency: 'USD',
    costAmountPerUnit: 0,
    costAmount: 0,
    sellCurrency: 'USD',
    sellAmountPerUnit: 0,
    sellAmount: 0,
    tax: '',
}

export const NEW_SELL_INITIAL_STATE = {
    idCost: 0,
    idCotizacion: 0,
    idSell: 0,
    description: '',
    validez: '',
    ruta: '',
    ttime: '',
    freeDays: '',
    carrier: '',
    charges: [NEW_SELL_CHARGE_INITIAL_STATE],
}

export const NEW_COST_INITIAL_STATE = {
    idCost: 0,
    idCotizacion: 0,
    agente: '',
    agenteNombre: '',
    description: '',
    validez: '',
    ruta: '',
    ttime: '',
    carrier: '',
    freeDays: '',
    comments: '',
    charges: [NEW_COST_CHARGE_INITIAL_STATE],
}

export const CLIENT_INITIAL_STATE = {
    nombreFantasia: '',
    razonSocial: '',
    rut: '',
    mail: '',
    direccion: '',
    codPostal: '',
    ciudad: '',
    paisCod: '',
    paisNombre: '',
    esCliente: 1,
    nuevoCliente: 0,
};

export const QUOTATION_RULE_FILTER_INITIAL_STATE = {
    id: 0,
    condition: '',
    filterType: '',
    value: '',
}

export const QUOTATION_RULE_INITIAL_STATE = {
    id: 0,
    name: '',
    send: false,
    createdOn: '',
    createdBy: null,
    filters: [QUOTATION_RULE_FILTER_INITIAL_STATE]
}

export const DRAFT_INITIAL_STATE = {
    type: "",
    date: "",
    name: "",
    display: {},
    data: {}
}

export const GENERIC_MODAL_INITIAL_STATE = {isVisible: false, selectedContent: null}

export const NOTE_INITIAL_STATE = {
    id: 0,
    name: '',
    date: '',
    value: '',
    mandatory: true,
    services: [],
    active: true,
}

export const NEW_RATE_INITIAL_STATE = {
    type: '',
    transport: '',
    mode: '',
    container: '',
    origin: '',
    destination: '',
    validFrom: '',
    validTo: '',
    cbmRatio: '',
    description: '',
    route: '',
    transitTime: '',
    frequency: '',
    comments: '',
    freeDays: '',
    stackable: true,
    owner: {
        id: '',
        code: '',
    },
    carrier: {
        id: '',
        code: '',
    },
    charges: [
        {
            concept: '',
            description: '',
            currency: '',
            amount: '',
            unit: '',
            calculator: '',
            ranges: [],
            minimum: {
                amount: null,
                value: null,
            },
            index: -1,
        }
    ]
}

export const NEW_RATE_VALIDATOR = {
    type: '',
    transport: '',
    mode: '',
    container: '',
    origin: '',
    destination: '',
    validFrom: '',
    validTo: '',
    cbmRatio: '',
    owner: {
        id: '',
        code: '',
    },
    carrier: {
        id: '',
        code: '',
    },
    charges: [],
}

export const NEW_RATE_CHARGES_VALIDATOR = {
    noCharges: {isInvalid: false, message: ""},
    invalidCharges: {isInvalid: false, message: ""},
    noRanges: {isInvalid: false, message: ""},
    invalidRanges: {isInvalid: false, message: ""},
}

export const UPDATE_RATE_VALIDATOR = {
    type: {type: ''},
    transport: '',
    mode: '',
    container: '',
    origin: '',
    destination: '',
    validFrom: '',
    validTo: '',
    cbmRatio: '',
    owner: {
        id: '',
        code: '',
    },
    carrier: {
        id: '',
        code: '',
    },
    charges: [],
}

export const NEW_QUOTATION_VALIDATOR = {
    client: '',
    transportMode: '',
    mode: '',
    origin: '',
    destination: '',
    containerData: '',
    pickupData: '',
    commodity: '',
    incoterm: '',
    value: '',
    pesoTotal: '',
    cbmTotal: '',
    commercial: '',
}

export const NEW_EXPRESS_QUOTATION_VALIDATOR = {
    client: '',
    tariff: '',
    value: '',
    shipperAddress: '',
    recipientAddress: '',
    monedaMercaderia: 'USD',
    insur: 'FEDEX',
    totalWeight: '',
    cbmTotal: '',
    chargeableTotal: '',
    ctnTotal: '',
    pltTotal: '',
};

export const PACKING_GENERAL_INITIAL_STATE = {
    ctnPlt: 'PLT',
    cantidad: 0,
    kg: 0,
    largo: 0,
    ancho: 0,
    alto: 0
}

export const QUOTATIONS_COLUMNS_VISIBILITY = {
    "identifier": "quotationsTable",
    "values": {
        "data.commercial": true,
        "data.requestedBy": false,
        "data.referencia": true,
        "data.cargowise": false,
        "data.fecha": true,
        "data.client.nombreFantasia": true,
        "data.client.reference": false,
        "data.transportMode": true,
        "data.modo": true,
        "data.origenCodigo": true,
        "data.destinoCodigo": true,
        "data.incoterm": true,
        "data.cbmTotal": true,
        "data.chargeableTotal": true,
        "data.status": true,
        "data.lastUpdate": false,
    }
}

export const MY_QUOTATIONS_COLUMNS_VISIBILITY = {
    "identifier": "myQuotationsTable",
    "values": {
        "data.commercial": false,
        "data.requestedBy": false,
        "data.referencia": true,
        "data.cargowise": false,
        "data.fecha": true,
        "data.client.nombreFantasia": true,
        "data.client.reference": false,
        "data.transportMode": true,
        "data.modo": true,
        "data.origenCodigo": true,
        "data.destinoCodigo": true,
        "data.incoterm": true,
        "data.cbmTotal": true,
        "data.chargeableTotal": true,
        "data.status": true,
        "data.lastUpdate": false,
    }
}

export const NOTES_INITIAL_STATE = {
    CLIENT: {type: NOTES_TYPES.CLIENT, value: ''},
    INTERNAL: {type: NOTES_TYPES.INTERNAL, value: ''},
    ON_REQUEST: {type: NOTES_TYPES.ON_REQUEST, value: ''},
}